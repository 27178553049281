import React from "react";
import styled from "styled-components";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const Text = styled.h1`
	font-family: "Poppins";
	font-weight: 900;
    font-style: normal;
	//font-size: ${(props) => props.size}rem;
	font-size: 3.15rem;
	line-height: 1.05;
	text-align: center;
	color: ${(props) => props.color};
	@media (min-width: 48em) {
		font-size: 4.375rem;
	}
	@media (min-width: 62em) {
		font-size: 5.375rem;
	}

	@media (min-width: 80em) {
		font-size: 6.375rem;
	}
`;

export default function SuperText({ text, color }) {
	const breakpoints = useBreakpoint();
	return (
		<Text color={color}>
			{text}
		</Text>
	);
}
